import axios from "../utils/axios";

export function callAPI(endPoint, filters) {
	return new Promise(function(resolve, reject) {
		axios.get('/api/survey/' + endPoint, { params: filters }).then(function(response) {
			if (response.data.error == 0) {
				resolve(response.data);
			}

			reject(response.data);
		})
	})
}




export function saveSurveyAnswers(surveyAnswers) {

	console.log("survey service - saveSurveyAnswers");
	console.log(surveyAnswers);

	return new Promise((resolve, reject) => {
		axios
			.post('/api/survey/save', surveyAnswers)
			.then((response) => {
				console.log("save!!!!!!!!", response);

				if (response.data.error == 0) {
					console.log("save post response", response.data);
					resolve(response.data);
				}

				reject(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
