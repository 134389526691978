import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { Doughnut } from "react-chartjs-2";
import { getLatestScore } from "../../../redux/actions/reportsActions";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
	height: 168px;
	position: relative;
`;

const DoughnutInner = styled.div`
	width: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -22px;
	text-align: center;
	z-index: 0;
`;

const LatestScore = ({ filters, surveyModules, theme }) => {
  const [latestScore, setLatestScore] = useState(0);

  useEffect(() => {
    let isMounted = true;

    console.log(surveyModules)
      /* surveyModules.map((currSurveyModule, index) => (
 
      )); */
    


    getLatestScore({
      'userId': filters.userId,
      'surveyId': filters.surveyId,
     // 'moduleId': filters.moduleId,
      'filterOPQIds': filters.filterOPQIds,
      'dateOption': filters.dateOption,
      'startdate': filters.startdate,
      'enddate': filters.enddate
    }).then(function (result) {
      if (isMounted) {
        setLatestScore(result);
      }
    });

    return () => { isMounted = false };
  });

  const data = {
    labels: ["", ""],
    datasets: [{
      data: [latestScore, 100 - latestScore],
      backgroundColor: [
        'rgb(115, 230, 132)',
        'rgb(200, 204, 201)'
      ],
      borderWidth: 5,
      borderColor: theme.palette.background.paper,
    }]
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 80,
  };

  return (
    <Card mb={3}>
      <CardHeader title="Latest Overall Score" />

      <CardContent>
        <ChartWrapper>
          <DoughnutInner variant="h4">
            <Typography variant="h1">{latestScore}%</Typography>
          </DoughnutInner>

          <Doughnut data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(LatestScore);