import React from "react";
import styled from "styled-components/macro";
import {
	Box,
	Card as MuiCard,
	CardContent as MuiCardContent,
	CardActionArea,
	CardActions,
	Button as MuiButton,
	Chip as MuiChip,
	Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import EditIcon from "@material-ui/icons/Edit";
import KnowledgeArticleDetail from "./KnowledgeArticleDetail";

const Button = styled(MuiButton)(spacing);
const Chip = styled(MuiChip)(spacing);
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CardContent = styled(MuiCardContent)`position: relative;`;
const Spacer = styled.div(spacing);

const KnowledgeArticle = ({ currArticle, index }) => {	
	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	var categories = [];	
	if (currArticle.knowledgeArticleCategories != null && currArticle.knowledgeArticleCategories.length > 0 ){
		categories = currArticle.knowledgeArticleCategories.split(',');	
	}

	const ProductsChip = styled(Chip)`
		height: 20px;
		padding: 4px 0;
		font-size: 90%;
		background-color: ${(props) => props.bg};
		color: white;
		margin-right: 10px;
		margin-bottom: 10px;
	`;

	return (
		<Card mb={3}>
			<CardActionArea>
				<CardContent>
					<Typography variant="h6" mb={4}>
						{currArticle.knowledgeArticleTitle}
					</Typography>

					{categories.map(function (cat, index) {

						var catColour = cat.split("_")[0];
						var catName = cat.split("_")[1];						

						return (
							<ProductsChip
								bg={catColour}
								size="small"
								label={catName}								
							/>
						);
					})}										
					<Spacer mb={12} />
				</CardContent>

			</CardActionArea>
			<CardActions>
				<Button size="small" color="primary" startIcon={<EditIcon />} onClick={handleClickOpen}>Learn More</Button>
				<KnowledgeArticleDetail open={open} onClose={handleClose} title={currArticle.knowledgeArticleTitle} content={currArticle.knowledgeArticleContent} />
			</CardActions>
		</Card>
	);
};

export default KnowledgeArticle;
