import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";

import {
	Grid,
	Card as MuiCard,
	CardHeader,
	Typography as MuiTypography
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import AnswersResults from "./AnswersResults";

import { getSurveyModules } from "../../../redux/actions/reportsActions";

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)(spacing);

const AnswersResultsSummary = ({ filters, showWindowPrint, theme }) => {
	const [surveyModules, setSurveyModules] = useState(null);

	useEffect(() => {
		let isMounted = true;

		getSurveyModules({
			'userId': filters.userId,
			'surveyId': filters.surveyId,
			'moduleId': filters.moduleId,
			'filterOPQIds': filters.filterOPQIds,
			'dateOption': filters.dateOption,
			'startdate': filters.startdate,
			'enddate': filters.enddate
		}).then(function(result) {
			if (isMounted) {
				setSurveyModules(result);
			}
		});

		return () => { isMounted = false };
   }, []);

	return (
		<Card mb={3}>
			<CardHeader title="Result Summary" />
			<Typography m={4}>{"To view each module result summary, click on the + to its right. If you require assistance, check out the User Guides here or please do contact us and we'll be happy to help"}</Typography>

			<Grid container spacing={4} columns={{ xs: 4, md: 12, lg: 12 }}>
				{surveyModules != null && surveyModules.map((currSurveyModule, index) => (
					<Grid item xs={12} md={12} lg={12} key={"AnswersResults_" + index}>
						<AnswersResults index={index} surveyModule={currSurveyModule} filters={filters} showWindowPrint={showWindowPrint} />
					</Grid>
				))}
			</Grid>
		</Card>
	);
};

export default withTheme(AnswersResultsSummary);
