import React from "react";
import styled from "styled-components/macro";
import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardActionArea,
  CardActions,
  Button as MuiButton,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { orange, green, blue } from "@material-ui/core/colors";
import * as Icon from "@material-ui/icons";

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const CardContent = styled(MuiCardContent)`position: relative;`;
const CheckCircleDone = styled(Icon.CheckCircle)`color: ${blue[600]};`;




 
const SurveyModule = ({ id, title, description, score, surveyUserData, userAnswers, colourCode }) => {

  var opacity = "1";

  if (colourCode == "" ){
    var colour = "#cdcdcd";  
  }else{
    var colour = colourCode;  
  }

  var dateString = "";
  if (surveyUserData != null && surveyUserData.length > 0){
    dateString = "on " + surveyUserData[0].date;
  }
  if (userAnswers != null && userAnswers == 0 ){
    //incomplete
    opacity = "0.2";
  }

  const TaskBadge = styled.div`
    background: ${(props) => props.bg};
    opacity: ${(props) => props.op};
    width: 65px;
    height: 20px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 8px;
  `;
  const CheckCircleDone = styled(Icon.CheckCircle)`
    color: ${(props) => props.bg};
    opacity: ${(props) => props.op};
  `;

  

  return (
    <Card mb={3}>
      <CardActionArea>
      <CardContent>

        <Typography variant="h6" mb={4}>
          {title}
        </Typography>       

          <TaskBadge bg={colour} op={opacity} />
          <CheckCircleDone bg={colour} op={opacity} />          

        <Typography variant="subtitle2" mb={3}>
            <Box fontWeight="fontWeightRegular">{description}</Box>
        </Typography>

          {score > 0 ? (
            <Box>
             <Typography variant="subtitle2" mb={3}>
              <Box fontWeight="fontWeightBold">Latest Score: {score}%</Box>
            </Typography>
              <Typography variant="subtitle2" mb={3}>
                <Box fontWeight="fontWeightNormal">{dateString}</Box>
              </Typography>
               </Box>
          ) : (
          
              <Typography variant="subtitle2" mb={3}>
                <Box fontWeight="fontWeightBold">Not completed</Box>
              </Typography>
          )}
       
         
      </CardContent>

    </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" href={`/assessYourBusinessSurvey/${id}`}>
          Assess Now
        </Button>
        <Button size="small" color="primary" href={`/viewYourResults/7034/`}>
          View Reports
        </Button>
        <Button size="small" color="primary" href={`/accessKnowledgeInsights/m${id}`}>
          Read Insights
        </Button>
        
      </CardActions>
    </Card>
  );
};

export default SurveyModule;
