import React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
	Button
} from "@material-ui/core";

import { Bar } from "react-chartjs-2";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ChartWrapper = styled.div`
	height: 340px;
	width: 100%;
`;

function AnswersBreakdownModal(props) {
	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	};

	let scoresLabels = [];
	let scoresQtys = [];
	let scoresColours = [];
	let scoresTotal = 0;

	if (open) {
		var scoresLabelsTotal = props.scoresBreakdown.length;
		var currScoresAnswers = props.scoresBreakdown;
/* 
		for (var currScore = 0; currScore < scoresLabelsTotal; currScore++) {
			var currAnswerScore = 0;

			if (currScoresAnswers[currScore]) {
				currAnswerScore = currScoresAnswers[currScore].count;
			}

			scoresQtys.push(currAnswerScore);
			scoresTotal += currAnswerScore;
			scoresLabels.push(currScore);
		}

		scoresTotal += 2;

 */
		//testing!
		/* scoresLabels = ['10/07/2022', '12/07/2022', '14/07/2022'];
		scoresQtys = [95, 65, 100]; */

		scoresLabels = props.scoresBreakdownDates;
		scoresQtys = props.scoresBreakdownValues;
		
  
		for (var i = 0; i < scoresQtys.length; i++) {
			var currAnswerScoreColour = '#c00000';

			if (scoresQtys[i] == 0) {
				currAnswerScoreColour = '#c00000';
			} else if (scoresQtys[i] == 1 ){
				currAnswerScoreColour = '#c00000';
			} else if (scoresQtys[i] == 2) {
				currAnswerScoreColour = '#f07a05';
			} else if (scoresQtys[i] == 3) {
				currAnswerScoreColour = '#f07a05';
			} else if (scoresQtys[i] == 4) {
				currAnswerScoreColour = '#f07a05';
			} else if (scoresQtys[i] == 5) {
				currAnswerScoreColour = '#f07a05';
			} else if (scoresQtys[i] == 6) {
				currAnswerScoreColour = '#00af50';
			} else if (scoresQtys[i] == 7) {
				currAnswerScoreColour = '#00af50';
			} else if (scoresQtys[i] == 8) {
				currAnswerScoreColour = '#00af50';
			} else if (scoresQtys[i] == 9) {
				currAnswerScoreColour = '#00af50';
			} else if (scoresQtys[i] == 10) {
				currAnswerScoreColour = '#e6ae05';
			}			
			scoresColours.push(currAnswerScoreColour);
		} 


		console.log(scoresQtys)
		console.log(scoresColours)

	}

	const firstDatasetColor = 'Orange';

	const data = {
		labels: scoresLabels,
		datasets: [
			{
				label: "",
				//backgroundColor: firstDatasetColor,
				backgroundColor: scoresColours,
				data: scoresQtys,
				barPercentage: 0.5,
				categoryPercentage: 1,
			}
		],
	};
 
	const options = {
		maintainAspectRatio: false,
		cornerRadius: 2,
		legend: {
			display: false,
			position: 'bottom',
			color: "transparent"
		},
		scales: {
			yAxes: [
				{
					gridLines: {
						display: true,
					},
					ticks: {
						beginAtZero: true,
						max: 10
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						display: false
					},
					ticks: {
						/*fontColor: theme.palette.text.secondary,*/
						//fontColor: 'blue',
					},
				},
			],
		}
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle>Distribution of Individual Answers</DialogTitle>

			<DialogContent>
				<ChartWrapper>
					<Bar data={data} options={options} />
				</ChartWrapper>

				<DialogContentText>
				</DialogContentText>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}

export default withTheme(AnswersBreakdownModal);
