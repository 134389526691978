import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
	Divider as MuiDivider,
	Typography as MuiTypography,
	Card as MuiCard,
	Link,
	Breadcrumbs as MuiBreadcrumbs
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { lighten, makeStyles } from "@material-ui/core/styles";

import { 
	getSurveyQuestions,
	getSurveyQuestionsDataV2,
	saveSurveyAnswersAction
} from "../../../redux/actions/surveyActions";

import SurveyForm from "./SurveyForm";


const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
 
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); 


const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
		},
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	margin: {
		margin: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		width: "100%",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

function AssessYourBusinessSurvey() {

	const [data, setData] = React.useState([]);
	const [moduleName, setModuleName] = React.useState([]);
	const [moduleDescription, setModuleDescription] = React.useState([]);
	const [surveyId, setSurveyId] = React.useState([]);
	const { sid } = useParams();
	const moduleId = sid;

	const userState = useSelector((state) => state.authReducer);
	const user = userState.user;
	var userId = user.id;
	if (user.parent_id && user.parent_id > 0) {
		userId = user.parent_id;
	}

	//	const [formQuestions, setFormQuestions] = useState([0]);
	var formQuestions = ["0"];

	useEffect(() => {
		getSurveyQuestionsDataV2( {
			'moduleId': moduleId 
			}).then(function(result) {				
				setData(result.surveyQuestions);
				setModuleDescription(result.moduleDescription);
				setModuleName(result.moduleName);
				setSurveyId(result.surveyId);

				//setFormQuestions(["aa","bbb"]);
				formQuestions = ["aa","bbb"]
			});
	}, []);

	return (
        <React.Fragment>
            <Helmet title="Assess Your Business" />
            <Typography variant="h3" gutterBottom display="inline">
                {moduleName}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
				<Link component={NavLink} exact to="/assessYourBusiness/">
                    Dashboard
                </Link>
				<Link component={NavLink} exact to="/assessYourBusiness/">
					Assess Your Business
				</Link>
				<Typography>{moduleName}</Typography>
            </Breadcrumbs>

            <Divider my={6} />
	 
			<SurveyForm userId={userId} surveyId={surveyId} moduleId={moduleId} moduleDescription={moduleDescription} data={data} />

        </React.Fragment>
	);
}

export default AssessYourBusinessSurvey;
