import { callAPI } from "../../services/reportsService";

export function getLatestSurveyUserId(filters) {
	return callAPI('getLatestSurveyUserId', filters)
		.then((response) => {
			return response.moduleScorePercent;
		})
		.catch((error) => {
			throw error;
		})
}

export function getLatestScore(filters) {
	return callAPI('getLatestScore', filters)
		.then((response) => {
			return response.moduleScorePercent;
		})
		.catch((error) => {
			throw error;
		})
}

export function getOverallScore(filters) {
	return callAPI('getOverallScore', filters)
		.then((response) => {
			return response.moduleScorePercent;
		})
		.catch((error) => {
			throw error;
		})
}

export function getResponsesTotals(filters) {
	return callAPI('getResponsesTotals', filters)
		.then((response) => {
			return {
				todayTotal: response.todayTotal,
				overallTotal: response.overallTotal,
				filteredTotal: response.filteredTotal
			};
		})
		.catch((error) => {
			throw error;
		})
}

export function getModuleBreakDown(filters) {
	return callAPI('getModuleBreakDown', filters)
		.then((response) => {
			return {
				questions: response.questions,
				answers: response.answers
			};
		})
		.catch((error) => {
			throw error;
		})
}

export function getModuleBreakDownLatestScore(filters) {
	return callAPI('getModuleBreakDownLatestScore', filters)
		.then((response) => {
			return {
				moduleNames: response.moduleNames,
				moduleScoresData: response.moduleScoresData
			};
		})
		.catch((error) => {
			throw error;
		})
}


export function getSurveyModules(filters) {
	return callAPI('getSurveyModules', filters)
		.then((response) => {
			return response.surveyModules;
		})
		.catch((error) => {
			throw error;
		})
}

export function getSurveyModulesLatestScore(filters) {
	return callAPI('getSurveyModulesLatestScore', filters)
		.then((response) => {
			return response.surveyModules;
		})
		.catch((error) => {
			throw error;
		})
}

export function getModuleAnswers(filters) {
	return callAPI('getModuleAnswers', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})
}

export function getModuleAnswersLatestScores(filters) {
	return callAPI('getModuleAnswersLatestScores', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})
}

export function getSurveys(filters) {
	return callAPI('getSurveys', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})
}