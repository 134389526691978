import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import {
	Grid,
	Divider as MuiDivider,
	Typography as MuiTypography,
	Box,
	Stack ,
	Button,
	Snackbar
} from "@material-ui/core";

import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";

import Filters from "./Filters";
import Approach from "./Approach";
import OverallScore from "./OverallScore";
import LatestScore from "./LatestScore"; 
import ResponseTotals from "./ResponseTotals";
import Stats from "./Stats";
import SurveyModule from "./SurveyModule";
import Insights from "./Insights"; 
import {	
	getAdminSurveyModules,
	getUserSurveyAnswerScore
} from "../../../redux/actions/surveyActions";

import { 
	getSurveyModules,
	getSurveyModulesLatestScore,
	getLatestSurveyUserId
} from "../../../redux/actions/reportsActions";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AssessYourBusiness() { 	
	const userState = useSelector((state) => state.authReducer);
	const user = userState.user;
	var userId = user.id;
	if (user.parent_id && user.parent_id > 0) {
		userId = user.parent_id;
	}
	
	const { sid } = useParams();

	console.log("sid:" + sid);

	const [latestSurveyUserId, setLatestSurveyUserId] = useState(null);
	const [surveyModules, setSurveyModules] = useState(null);
	const [updateState, setUpdateState] = useState(0);

	const [alertOpen, setAlertOpen] = React.useState(false);

	const [userCurrentScore, setUserCurrentScore] = React.useState(-1);
	const [userCurrentModuleId, setUserCurrentModuleId] = React.useState(0);

	const handleClick = () => {
		setAlertOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlertOpen(false);
	};

	const [filters, setFilters] = useState({
		'dateOption': 'allDates',
		'startdate': '',
		'enddate': '',
		'filterOPQIds': '',
		'surveyId': 7034,
		'moduleId': 0,
		'surveyUserId': 0,
		'userId': userId
	});

	useEffect(() => {
		let isMounted = true;
  
		getSurveyModulesLatestScore({
				'userId': userId,
				'surveyId': filters.surveyId,
				'moduleId': filters.moduleId,
				'filterOPQIds': filters.filterOPQIds,
				'dateOption': filters.dateOption,
				'startdate': filters.startdate,
				'enddate': filters.enddate 
			}).then(function (result) {
				if (isMounted) {
					setSurveyModules(result);

					//if a survey was just completed! show results
					if (sid > 0) {
						var currFilters = filters;
						currFilters.surveyUserId = sid;
						setFilters(currFilters);

						getUserSurveyAnswerScore(filters).then(function (result) {

							setUserCurrentScore(result.userSurveyScore);
							setUserCurrentModuleId(result.moduleId);
							setAlertOpen(true);


						});
					}
				}
		});
		return () => { isMounted = false };
	}, []);


	return (
		<React.Fragment>
			<Helmet title="Assess Your Business" />
			<Grid justify="space-between" container spacing={6}>
				<Grid item>
					<Typography variant="h3" gutterBottom>
						Assess Your Business
					</Typography>					
				</Grid>

				<Grid item>
					{/* 
					<Filters />
					 */}
				</Grid>
			</Grid>

			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12} md={12} lg={6}>
					<Approach />	
				</Grid>
				<Grid item xs={12} md={12} lg={6} sm container>
					
					<Grid container spacing={6}>

						<Grid item xs={12} md={6} lg={6}>							
							<LatestScore key={"OverallScore" + updateState} filters={filters} surveyModules={surveyModules}/>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<ResponseTotals key={"ResponsesTotals" + updateState} filters={filters} />
						</Grid>
					</Grid>
					<Grid container spacing={6}>
						<Grid item xs={12} lg={12}>

							{userCurrentScore != -1
								? <Alert >
									<AlertTitle><strong>Thank You</strong> </AlertTitle>
									<Typography component="p">Your answers have been successfully submitted!</Typography>
									<Typography component="h3">User Score: <strong>{userCurrentScore} %</strong></Typography>
									
									<Button size="small" color="primary" href={`/accessKnowledgeInsights/${userCurrentModuleId}`}>
										View Insights
									</Button>
									<Button size="small" color="primary" href={`/viewYourResults/5449/`}>
										View Reports
									</Button>

								</Alert>
								: ''
							}

						</Grid>	
					</Grid>								
				</Grid>
			</Grid>

			<Grid container spacing={6}>

				{surveyModules != null && surveyModules.map((currSurveyModule, index) => (

					<Grid item xs={12} md={4} lg={3} key={"SurveyModule_" + index}>
						<SurveyModule id={currSurveyModule.moduleId} 
							title={currSurveyModule.moduleName}
							colourCode={currSurveyModule.moduleColour}
							description={currSurveyModule.moduleSummary} 
							score={currSurveyModule.overallScore} 
							surveyUserData={currSurveyModule.surveyUserData}
							userAnswers={currSurveyModule.userAnswers} />
 
					</Grid>
				))} 				

				<Grid item xs={12} lg={3}>
					<Insights />
				</Grid>

			</Grid>
			
			<Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>				
				
				<Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
					<AlertTitle><strong>Thank You</strong> </AlertTitle>
					<Typography component="p">Your answers have been successfully submitted!</Typography>
					<Typography component="h3">User Score: <strong>{userCurrentScore} %</strong></Typography>
					<Button size="small" color="primary" href={`/accessKnowledgeInsights/${userCurrentModuleId}`}>
						View Insights
					</Button>
					<Button size="small" color="primary" href={`/viewYourResults/7034/`}>
						View Reports
					</Button>
				</Alert>

			</Snackbar>

		</React.Fragment>
	);
}

export default AssessYourBusiness;
