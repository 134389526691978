import React, { useState } from "react";
import styled, { withTheme } from "styled-components/macro";

import {
	Grid,
	Typography as MuiTypography,
	Button
} from "@material-ui/core";

import ChatIcon from '@material-ui/icons/Chat';
import InsertChartIcon from '@material-ui/icons/InsertChart';

import AnswersBreakdownModal from "./AnswersBreakdownModal";
import AnswersBreakdown from "./AnswersBreakdown";

import RenderSmiley from "./RenderSmiley";

const Typography = styled(MuiTypography)`
	span {
		font-weight: bold;
	}
`;

const AnswersResultsCriteria = ({ currCriteria, showWindowPrint, index }) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return(
		<Grid container spacing={1} key={index} pt={5}>
			<Grid item xs={7} lg={7}>
				<Typography>{currCriteria.criteriaText}</Typography>
			</Grid>

			<Grid item xs={1} lg={1}>
				<Typography><RenderSmiley criteria={currCriteria} /></Typography>
			</Grid>

			<Grid item xs={2} lg={2}>
				<Typography align="right"><ChatIcon /> {currCriteria.answercount} responses</Typography>
			</Grid>

			<Grid item xs={2} lg={2}>
				{ showWindowPrint == 0
					? <Button size="small" startIcon={<InsertChartIcon />} onClick={handleClickOpen}>View Report</Button>
					: ''
				}

				{ showWindowPrint == 0
					? <AnswersBreakdownModal open={open} onClose={handleClose} 
						scoresBreakdown={currCriteria.scoresBreakdown} 
						scoresBreakdownList={currCriteria.scoresBreakdownList}
						scoresBreakdownDates={currCriteria.scoresBreakdownDates} 
						scoresBreakdownValues={currCriteria.scoresBreakdownValues} 

						/>
					: ''
				}
			</Grid>

			{ showWindowPrint == 1
				? 	<Grid item xs={12} lg={12}>
						<AnswersBreakdown key={'AnswersBreakdown' + index} index={index} scoresBreakdown={currCriteria.scoresBreakdown} scoresBreakdownList={currCriteria.scoresBreakdownList} />
					</Grid>
				: ''
			}
		</Grid>
	);
}

export default withTheme(AnswersResultsCriteria);