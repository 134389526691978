import * as types from "../../constants";

import {
	autoSignIn as authAutoSignIn,
	signIn as authSignIn,
	signUp as authSignUp,
	recoverPassword as authRecoverPassword,
} from "../../services/authService";

export function autoSignIn(credentials) {
	return authAutoSignIn(credentials)
		.then((response) => {
			var data = {
				type: types.AUTH_SIGN_IN_SUCCESS,
				id: response.userId,
				email: response.userLogin,
				name: response.userFirstName + ' ' + response.userLastName,
				isSuperdmin: response.isSuperdmin,
				subAccounts: response.subAccounts,
				mainAccountUserId: response.mainAccountUserId,
				parent_id: response.parent_id,
				surveyId: response.surveyId,
				pageId: response.pageId
			}

			return data;
		})
		.catch((error) => {
			throw error;
		});
}

export function signIn(credentials) {
	return async (dispatch) => {
		dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

		return authSignIn(credentials)
			.then((response) => {
				var data = {
					type: types.AUTH_SIGN_IN_SUCCESS,
					id: response.userId,
					email: response.userLogin,
					name: response.userFirstName + ' ' + response.userLastName,
					isSuperdmin: response.isSuperdmin,
					subAccounts: response.subAccounts,
					mainAccountUserId: response.mainAccountUserId,
					parent_id: response.parent_id
				}

				dispatch(data);
			})
			.catch((error) => {
				dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
				throw error;
			});
	};
}

export function recoverPassword(credentials) {
	return async (dispatch) => {
		dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

		return authRecoverPassword(credentials)
			.then((response) => {
				dispatch({
					type: types.AUTH_RESET_PASSWORD_SUCCESS,
					email: response.userEmail,
				});
			})
			.catch((error) => {
				dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
				throw error;
			});
	};
}

export function resetPassword(credentials) {
	return async (dispatch) => {
		dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

		return authResetPassword(credentials)
			.then((response) => {
				dispatch({
					type: types.AUTH_RESET_PASSWORD_SUCCESS,
					email: response.userEmail,
				});
			})
			.catch((error) => {
				dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
				throw error;
			});
	};
}

export function signUp(credentials) {
	return async (dispatch) => {
		dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

		return authSignUp(credentials)
			.then((response) => {
				dispatch({
					type: types.AUTH_SIGN_UP_SUCCESS,
					id: response.id,
					email: response.email,
					name: response.name,
				});
			})
			.catch((error) => {
				dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
				throw error;
			});
	};
}

export function signOut() {
	return async (dispatch) => {
		dispatch({
			type: types.AUTH_SIGN_OUT,
		});
	};
}