import React, { useState } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import {
	Chip,
	Paper,
	Drawer as MuiDrawer,
	List as MuiList,
	ListItem,
	ListItemText,
	Typography,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';

import { sidebarRoutes as routes } from "../routes/index";

const Drawer = styled(MuiDrawer)`
	border-right: 0;

	> div {
		border-right: 0;
	}
`;


const useStyles = makeStyles({
	menuHidden: {
		display: 'none'
	},
});

const bgColor = '#182529';

const Scrollbar = styled(PerfectScrollbar)`
	//background-color: ${(props) => props.theme.sidebar.background};
	background-color: ${bgColor};
	border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
	//background-color: ${(props) => props.theme.sidebar.background};
	background-color: ${bgColor};
`;

const Items = styled.div`
	padding-top: ${(props) => props.theme.spacing(2.5)}px;
	padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Logo = styled(Paper)`
	background-color: ${bgColor};
	padding: 2rem 1rem 2rem 1rem;
	justify-content: center;
	display: flex;
	cursor: pointer;
	border-radius: 0;
`;

const Link = styled(ListItem)`
	padding: 1rem 1rem 1rem 2rem;

	span {
	}

	&:hover span	 {
		color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
	}

	&:hover {
		background-color: ${(props) =>
			darken(0.015, props.theme.sidebar.background)};
	}

	/*
	&.${(props) => props.activeClassName} {
		background-color: ${(props) =>
			darken(0.03, props.theme.sidebar.background)};

		span {
			color: ${(props) => props.theme.sidebar.color};
		}
	}*/
`;

const LinkText = styled(ListItemText)`
	color: white;
	font-size: 15px;
`;

const LinkBadge = styled(Chip)`
	font-size: 11px;
	font-weight: ${(props) => props.theme.typography.fontWeightBold};
	height: 20px;
	position: absolute;
	right: 28px;
	top: 8px;
	background: ${(props) => props.theme.sidebar.badge.background};

	span.MuiChip-label,
	span.MuiChip-label:hover {
		cursor: pointer;
		color: ${(props) => props.theme.sidebar.badge.color};
		padding-left: ${(props) => props.theme.spacing(2)}px;
		padding-right: ${(props) => props.theme.spacing(2)}px;
	}
`;

const SidebarSection = styled(Typography)`
	color: ${(props) => props.theme.sidebar.color};
	padding: ${(props) => props.theme.spacing(4)}px
		${(props) => props.theme.spacing(7)}px
		${(props) => props.theme.spacing(1)}px;
	opacity: 0.9;
	display: block;
`;

const Sidebar = ({ classes, staticContext, location, ...rest }) => {
	const classesNames = useStyles();

	const initOpenRoutes = () => {
		/* Open collapse element that matches current url */
		const pathName = location.pathname;

		let _routes = {};

		routes.forEach((route, index) => {
			const isActive = pathName.indexOf(route.path) === 0;
			const isOpen = route.open;
			const isHome = route.containsHome && pathName === "/";

			_routes = Object.assign({}, _routes, {
				[index]: isActive || isOpen || isHome,
			});
		});

		return _routes;
	};

	const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

	const toggle = (index) => {
		// Collapse all elements
		Object.keys(openRoutes).forEach(
			(item) =>
				openRoutes[index] ||
				setOpenRoutes((openRoutes) =>
					Object.assign({}, openRoutes, { [item]: false })
				)
		);

		// Toggle selected element
		setOpenRoutes((openRoutes) =>
			Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
		);
	};

	function redirect(url) {
		window.location.href = process.env.REACT_APP_DOBIQUITY_URL + url;
	}

	const SideBarLink = (category) => {
		if (category.url) {
			return (
				//<Link button dense exact activeClassName="active" onClick={() => redirect(category.url)}>
				<Link dense onClick={() => redirect(category.url)}>
					<LinkText>{category.id}</LinkText>
					{category.icon ? <LinkBadge label={category.icon} /> : ""}
				</Link>
			);
		} else if (category.show == true) {
			return (
				//<Link button dense component={NavLink} exact to={category.path} activeClassName="active" sx={{ display: 'block' }}>
				<Link dense component={NavLink} exact to={category.path} sx={{ display: 'block' }}>
					<LinkText>{category.id}</LinkText>
					{category.icon ? <LinkBadge label={category.icon} /> : ""}
				</Link>
			);
		} else {
			return (
				//<Link button dense component={NavLink} exact to={category.path} className={classesNames.menuHidden} activeClassName="active">
				<Link dense component={NavLink} exact to={category.path} className={classesNames.menuHidden}>
					<LinkText>{category.id}</LinkText>
					{category.icon ? <LinkBadge label={category.icon} /> : ""}
				</Link>
			);
		}
	}

	return (
		<Drawer variant="permanent" {...rest}>
			<Logo component={NavLink} to="/">
				<img src="/static/img/logos/logo_admin2.png" />
			</Logo>

			<Scrollbar>
				<List disablePadding>
					<Items>
						{routes.map((category, index) => (
							<React.Fragment key={index}>
								{category.header ? (
									<SidebarSection>{category.header}</SidebarSection>
								) : null}

								{SideBarLink(category)}
							</React.Fragment>
						))}
					</Items>
				</List>
			</Scrollbar>
		</Drawer>
	);
};

export default withRouter(Sidebar);
