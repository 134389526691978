import { 
	callAPI,
} from "../../services/knowledgeService";

 

 
export function getKnowledgeData(filters) {
	return callAPI('getKnowledgeData', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})
}

