import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";

import {
	Grid,
	Typography as MuiTypography,
	Accordion as MuiAccordion,
	AccordionSummary as MuiAccordionSummary,
	AccordionDetails
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { getModuleAnswers, getModuleAnswersLatestScores } from "../../../redux/actions/reportsActions";
import AnswersResultsCriteria from "./AnswersResultsCriteria";
import { render } from "react-dom";

const Accordion = styled(MuiAccordion)`
	border-radius: 3px;
	border: 1px solid #233044;
	margin: 0.5rem !important;
`;

const AccordionSummary = styled(MuiAccordionSummary )`
	border-top: 2px solid #233044;
`;

const Typography = styled(MuiTypography)`
	span {
		font-weight: bold;
	}
`;

const AnswersResults = ({ surveyModule, filters, showWindowPrint, theme }) => {
	const [answers, setAnswers] = useState(null);
	const [isExpanded, setIsExpanded] = useState(showWindowPrint == 1 ? true : false);

	const surveyId = surveyModule.surveyId;
	const moduleId = surveyModule.moduleId;

	useEffect(() => {
		getModuleAnswersLatestScores({
			'userId': filters.userId,
			'surveyId': surveyId,
			'moduleId': moduleId,
			'filterOPQIds': filters.filterOPQIds,
			'dateOption': filters.dateOption,
			'startdate': filters.startdate,
			'enddate': filters.enddate
		}).then(function(result) {
			setAnswers(result.moduleAnswers);
		});
   }, []);

	return (
		<Accordion defaultExpanded={isExpanded}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
				<Grid container spacing={1}>
					<Grid item xs={11} lg={10}>
						<Typography variant="h6">{surveyModule.moduleName}</Typography>
					</Grid>
					<Grid item xs={1} lg={2}>
						<Typography align="right"><span>{surveyModule.overallScore} %</span></Typography>
					</Grid>
				</Grid>
			</AccordionSummary>

			<AccordionDetails>
				<Grid container>
					{answers && answers.map((el, index) => {
						return ( <AnswersResultsCriteria key={index} currCriteria={el} index={index} showWindowPrint={showWindowPrint} /> );
					})}
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

export default withTheme(AnswersResults);
