import React from "react";
import styled, { withTheme } from "styled-components/macro";

import RenderSmiley from "./RenderSmiley";

function AnswersBreakdown(props) {
	let scoresLabels = [];
	let scoresQtys = [];
	let scoresTotal = 0;

	var scoresLabelsTotal = props.scoresBreakdown.length;
	var currScoresAnswers = props.scoresBreakdown;

	for (var currScore = 0; currScore < scoresLabelsTotal; currScore++) {
		var currAnswerScore = 0;

		if (currScoresAnswers[currScore]) {
			currAnswerScore = currScoresAnswers[currScore].count;
		}

		scoresQtys.push(currAnswerScore);
		scoresTotal += currAnswerScore;
		scoresLabels.push(currScore);
	}

	return (
		<table key={'AnswersBreakdownTable' + props.index} border={1} cellPadding={4} cellSpacing={0} width={'100%'} style={{ tableLayout: 'fixed', marginTop: '1rem', marginBottom: '1.5rem' }}>
			<tbody>
				<tr><td colSpan={scoresQtys.length + 1} align={'center'} style={{ backgroundColor: '#f7f9fc', fontWeight: 'bold' }}>
					Breakdown Of Responses
				</td></tr>

				<tr>
					<td style={{ backgroundColor: '#f7f9fc', fontWeight: 'bold' }}>
						Score
					</td>
					{ scoresQtys.map(function(el, index) {
						return (
							<td align={'center'} key={'tHeader' + index}>
								{index} <RenderSmiley answersScore={index} />
							</td>
						)
					}) }
				</tr>

				<tr>
					<td style={{ backgroundColor: '#f7f9fc', fontWeight: 'bold' }}>
						Count
					</td>
					{ scoresQtys.map(function(el, index) {
						return (
							<td align={'center'} key={'tresults' + index}>
								{el}
							</td>
						)
					}) }
				</tr>
			</tbody>
		</table>
	);
}

export default withTheme(AnswersBreakdown);
