import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
	Grid,
	Divider as MuiDivider,
	Typography as MuiTypography,
	Button,
	Link,
	Breadcrumbs as MuiBreadcrumbs,
} from "@material-ui/core";

import PdfIcon from '@material-ui/icons/PictureAsPdf';

import { spacing } from "@material-ui/system";




import ResponsesTotals from "./ResponsesTotals";
import ModuleBreakdown from "./ModuleBreakdown";
import OverallScore from "./OverallScore";
import AnswersResultsSummary from "./AnswersResultsSummary";
import Filters from "./Filters";
import { render } from "react-dom";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default() {
	const { sid } = useParams();
	const [updateState, setUpdateState] = useState(0);
	const [showWindowPrint, setShowWindowPrint] = useState(0);
	const userState = useSelector((state) => state.authReducer);
	const user = userState.user;
	var userId = user.id;
	if (user.parent_id && user.parent_id > 0){
		userId = user.parent_id;
	}

	const [filters, setFilters] = useState({
		'dateOption': 'allDates',
		'startdate': '',
		'enddate': '',
		'filterOPQIds': '',
		'surveyId': sid,
		'moduleId': 0,
		'userId': userId
	})

	const updateFilters = (arg_dateOption, arg_startDate, arg_endDate) => {
		var currFilters = filters;
		currFilters.dateOption = arg_dateOption;
		currFilters.startdate = arg_startDate;
		currFilters.enddate = arg_endDate;

		setFilters(currFilters);
		updateComponentState();
	}

	const updateComponentState = () => {
		var currState = updateState + 1;
		setUpdateState(currState);
	}

	const handlePrintToPdf = (event) => {
		setShowWindowPrint(1);
		updateComponentState();

		window.setTimeout(function() {
			document.querySelector('.MuiToolbar-root').style.display = 'none';

			document.querySelectorAll('.MuiSvgIcon-root').forEach(function(el) {
				el.style.display = 'none';
			})

			window.print();
		}, 750)
	}

	window.addEventListener("afterprint", function(event) {
		document.querySelector('.MuiToolbar-root').style.display = 'block';

		document.querySelectorAll('.MuiSvgIcon-root').forEach(function(el) {
			el.style.display = 'show';
		})

		setShowWindowPrint(0);
		updateComponentState();
	});

	return (
		<React.Fragment>
			<Typography variant="h3" gutterBottom>View Your Results</Typography>

			<Breadcrumbs aria-label="Breadcrumb" mt={2}>
				<Link component={NavLink} exact to="/assessYourBusiness/">
					Dashboard
				</Link>
				<Link component={NavLink} exact to="/assessYourBusiness/">
					Assess Your Business
				</Link>
				<Typography>Results</Typography>
			</Breadcrumbs>

			<Divider my={6} />

			<Grid container spacing={6}>
				{ showWindowPrint == 0
					?	<Grid item xs={12} lg={12}>
							<Button variant="contained" color="primary" style={{width: '16%', float: 'right' }} onClick={handlePrintToPdf} startIcon={<PdfIcon />}>Generate PDF</Button>
						</Grid>
					: ''
				}

				{ showWindowPrint == 0
					?	<Grid item xs={12} lg={12}>
							<Filters dateOption={filters.dateOption} startdate={filters.startdate} enddate={filters.enddate} bindIt={updateFilters.bind(this)} />
						</Grid>
					: ''
				}

				<Grid item xs={12} lg={8}>
					<ModuleBreakdown key={"ModuleBreakdown" + updateState} filters={filters} />
				</Grid>

				<Grid item xs={12} lg={4}>
					<OverallScore key={"OverallScore" + updateState} filters={filters} />

					<ResponsesTotals key={"ResponsesTotals" + updateState} filters={filters} />
				</Grid>
			</Grid>

			<AnswersResultsSummary key={"AnswersResultsSummary" + updateState} filters={filters} showWindowPrint={showWindowPrint} />
		</React.Fragment>
	);
}

export default Default;
