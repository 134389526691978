import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import styled from "styled-components/macro";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";


import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import EditIcon from "@material-ui/icons/Edit";

import {
	Briefcase,
	DollarSign,
	ExternalLink,
	Facebook,
	Home,
	Instagram,
	MapPin,
	ShoppingBag,
	Twitter,
	Circle,
	Info,
	Film,
	Send,
	Database as IconDB
} from "react-feather";

import pink from "@material-ui/core/colors/red";


import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
	Divider as MuiDivider,
	Typography as MuiTypography,
	Card as MuiCard,
	Link,
	Breadcrumbs as MuiBreadcrumbs,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Radio,
	RadioGroup
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { lighten, makeStyles } from "@material-ui/core/styles";


const Spacer = styled.div(spacing);

import {
	getSurveyQuestions,
	getSurveyQuestionsDataV2,
	saveSurveyAnswersAction
} from "../../../redux/actions/surveyActions";

import SurveyQuestion from "./SurveyQuestion";
import SurveyModal from "./SurveyModal";
import SurveyQuestionContainer from "./SurveyQuestionContainer";

import SurveyGuidanceModal from "./SurveyGuidanceModal";
import SurveyVideoModal from "./SurveyVideoModal";



import * as Yup from "yup";
//import { Formik } from "formik";
import { Field, Form, Formik, FormikProps } from 'formik';

import {
	Box,
	Button as MuiButton,
	CardContent,
	CircularProgress,
	Grid,
	TextField as MuiTextField
} from "@material-ui/core";

import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";



const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
		},
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	margin: {
		margin: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		width: "100%",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},

	radioBtnsMargin: {
		width: 20,
		height: 20,
		marginLeft: 20,
		marginRight: 10
	},
	radioBtnsColor0: {
		fontSize: 16,
		color: "#c00000"
	},
	radioBtnsColor1:{
		fontSize: 16,
		color: "#c00000"
	},
	radioBtnsColor2: {
		fontSize: 16,
		color: "#f07a05"
	},
	radioBtnsColor3: {
		fontSize: 16,
		color: "#f07a05"
	},
	radioBtnsColor4: {
		fontSize: 16,
		color: "#f07a05"
	},
	radioBtnsColor5: {
		fontSize: 16,
		color: "#f07a05"
	},
	radioBtnsColor6: {
		fontSize: 16,
		color: "#a4b309"
	},

	radioBtnsColor7: {
		fontSize: 16,
		color: "#a4b309"
	},
	radioBtnsColor8: {
		fontSize: 16,
		color: "#a4b309"
	},
	radioBtnsColor9: {
		fontSize: 16,
		color: "#a4b309"
	},
	radioBtnsColor10: {
		fontSize: 16,
		color: "#e6ae05"
	}
 


}));



const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)(spacing);


function SimpleDialog(props) {
	const { onClose, selectedValue, open } = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (value) => {
		onClose(value);
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle>Information window</DialogTitle>

			<DialogContent>
				<DialogContentText>
					Lorem ipsum, dolor sit amet consectetur adipisicing
					elit. Quos quis voluptatibus ullam, temporibus error
					tempore delectus odio voluptatum ad repellendus.
				</DialogContentText>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}


function SurveyForm({ userId, surveyId, moduleId, moduleDescription, data }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const auth = useSelector((state) => state.authReducer);
	const classes = useStyles();
 
	console.log("userId: " + userId );
	var initialValues = {

	};

	useEffect(() => {
		//var criteriaIds = [];
		data.map(function (curr, index) {
			//criteriaIds.push( curr.criteriaId);
			initialValues["criteriaId" + curr.criteriaId] = curr.criteriaId;
		});
		//console.log(initialValues);

	}, []);


	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required("Required")
	});


	const [score, setScore] = React.useState([]);


	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const [open2, setOpen2] = React.useState(false);
	const handleClickOpen2 = () => {
		setOpen2(true);
	};
	const handleClose2 = () => {
		setOpen2(false);
	};

	const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
		try {
			//alert(JSON.stringify(values, null, 2));

			var dataToSubmit = {
				userId: userId,
				surveyId: surveyId,
				moduleId: moduleId,
				data: values
			}
			console.log("Data Submitted!");
			console.log(dataToSubmit);

			//await dispatch(
			saveSurveyAnswersAction(dataToSubmit).then(function (result) {
				console.log("saveSurveyAnswersAction", result);

				setScore(result.userSurveyScore);

				resetForm();
				setStatus({ sent: true });
				setSubmitting(false);

				//use survey userId!!
				history.push("/assessYourBusiness/" + result.surveyUserId + "/");
			})
			//);


			//await timeOut(1500);


		} catch (error) {
			alert(error);
			setStatus({ sent: false });
			setErrors({ submit: error.message });
			setSubmitting(false);
		}
	};

	const [selectedValue, setSelectedValue] = React.useState('a');

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
	};

	const controlProps = (item) => ({
		checked: selectedValue === item,
		onChange: handleChange,
		value: item,
		name: 'color-radio-button-demo',
		inputProps: { 'aria-label': item },
	});
	


	return (
		<Formik
			initialValues={initialValues}
			//validationSchema={validationSchema}
			onSubmit={handleSubmit}
		
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
				status,
			}) => (
				<Card mb={6}>
					<CardContent>

						{status && status.sent && (
							<Grid container>
								<Grid item xs={12}>
									<Alert severity="success">
										<AlertTitle><strong>Thank You</strong> </AlertTitle>
										<Typography component="p">Your answers have been successfully submitted!</Typography>
										<Typography component="h3">User Score: <strong>{score} %</strong></Typography>
										<Button style={{
											backgroundColor: "#40c8f4",
											padding: "5px 25px",
											fontSize: "18px"
											}}
											variant="contained" color="default" href={`/viewYourResults/7034/`}>
											View Reports   <IconDB />
										</Button>
									</Alert>
								</Grid>
								<Grid item xs={12}>
									<br /><br /><br />
								</Grid>
							</Grid>
						)}
						{isSubmitting ? (
							<Box display="flex" justifyContent="center" my={6}>
								<CircularProgress />
							</Box>
						) : (
							<form onSubmit={handleSubmit}>

								<Typography variant="h6" gutterBottom>
									Read the statement below and rate your response accordingly
								</Typography>

								<Spacer mb={6} />
									<Button size="small" color="default" startIcon={<Info />}>Click the Icon for Guidance</Button>								
									<Button size="small" color="default" startIcon={<Film />} onClick={handleClickOpen2}>Learn More</Button>
									<SurveyModal open={open2} onClose={handleClose2} title="Module Guidance" content={moduleDescription} />
								<Divider my={6} />

								<Grid container spacing={6}>
									{
										data.map(function (currQuestion, index) {
											return (
												<Grid item md={12}>
													
													<Grid justify="space-between" container spacing={0}>
														<Grid item md={12} xs={12}>
															<SurveyQuestionContainer questionData={currQuestion} />																													
														</Grid>
														<Grid item md={1} xs={1}>
														</Grid>
														<Grid item md={11} xs={11}>

															<label htmlFor={currQuestion.criteriaId}></label>
															<Field name={currQuestion.criteriaId} >
																{
																	({ field }) => {
																		return currQuestion.answers.map((answer, i) => {
																			return (																				 
																				<React.Fragment key={"ans_" + currQuestion.criteriaId + answer}>
																					<input
																						className={classes["radioBtnsMargin"]}
																						type='radio'
																						id={answer}
																						{...field}
																						value={answer}
																						required={true}
																						checked={field.value === answer}
																					/>
																					<label className={classes["radioBtnsColor" + i]} htmlFor={answer}>{i}</label>

																				</React.Fragment>
																			)
																		});																		
																	}
																}
															</Field>
															
														</Grid>
													</Grid>

												</Grid>
											);
										})}
								</Grid>

								<Button
									type="submit"
									variant="contained"
									color="primary"
									mt={3}
								>
									Save changes
								</Button>
							</form>
						)}

					</CardContent>
				</Card>
			)}


		</Formik>
	);
}


export default SurveyForm;
