import React from "react";
import async from "../components/Async";

import {
	PieChart,
	Users,
	Search,
	Settings,
	Info
} from "react-feather";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const AutoSignIn = async(() => import("../pages/auth/AutoSignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const RecoverPassword = async(() => import("../pages/auth/RecoverPassword"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const AssessYourBusiness = async(() => import("../pages/dashboards/AssessYourBusiness"));
const UseOurTools = async(() => import("../pages/dashboards/UseOurTools"));
const ViewYourResults = async(() => import("../pages/dashboards/ViewYourResults"));
const ViewYourResultsList = async(() => import("../pages/dashboards/ViewYourResultsList"));
const AccessKnowledgeInsights = async(() => import("../pages/dashboards/AccessKnowledgeInsights"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const MenuRoutes = [
	{
		id: "Dashboard",
		url: '/dashboard?diagram=true',
		guard: AuthGuard
	},
	{
		id: "Knowledge Hub",
		url: '/knowledgehub',
		guard: AuthGuard
	},
	{
		id: "Manage Account",
		url: '/account',
		guard: AuthGuard
	},
	{
		id: "Manage Users",
		url: '/manage-users',
		guard: AuthGuard
	},
	{
		id: "Get Help",
		url: '/help',
		guard: AuthGuard
	},
	{
		id: "Assess Your Business",
		path: "/assessYourBusiness",
		//header: "Menu",
		//icon: <Search />,
		show: false,
		component: AssessYourBusiness,
		guard: AuthGuard
	}, {
		id: "Use Our Tools",
		path: "/useOurTools",
		//icon: <Settings />,
		show: false,
		component: UseOurTools,
		guard: AuthGuard
	}, {
		id: "View Your Results",
		path: "/viewYourResultsList",
		//icon: <PieChart />,
		show: false,
		component: ViewYourResultsList,
		guard: AuthGuard,
		hidden: true
	}, {
		id: "Access Knowledge & Insights",
		path: "/accessKnowledgeInsights",
		//icon: <Info />,
		show: false,
		component: AccessKnowledgeInsights,
		guard: AuthGuard,
	}
];

const authRoutes = {
	id: "Auth",
	path: "/auth",
	icon: <Users />,
	children: [
		{
			path: "/",
			name: "Sign In",
			component: SignIn,
		},
		{
			path: "/:hsh",
			name: "Sign In",
			component: AutoSignIn,
		},
		{
			path: "/auth/sign-in",
			name: "Sign In",
			component: SignIn,
		},
		{
			path: "/auth/sign-in/:hsh",
			name: "Sign In",
			component: AutoSignIn,
		},
		{
			path: "/auth/sign-up",
			name: "Sign Up",
			component: SignUp,
		},
		{
			path: "/auth/recover-password/",
			name: "Recover Password",
			component: RecoverPassword,
		},
		{
			path: "/auth/reset-password",
			name: "Reset Password",
			component: ResetPassword,
		},
		{
			path: "/auth/404",
			name: "404 Page",
			component: Page404,
		},
		{
			path: "/auth/500",
			name: "500 Page",
			component: Page500,
		}
	],
	component: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
	id: "Private",
	path: "/private",
	component: ProtectedPage,
	children: null,
	guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = MenuRoutes;

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [
	protectedPageRoutes,
	MenuRoutes
];

// Routes visible in the sidebar
export const sidebarRoutes = MenuRoutes;
