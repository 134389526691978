import React from "react";

const RenderSmiley = ({ criteria, answersScore }) => {
	var answerscore = criteria && criteria.answerscore || answersScore;
	var answerText = criteria && criteria.answerText || answersScore;
	var answertotal = criteria && criteria.scoresBreakdown.length || 10;
	var currCriteria = 0;

	if (answertotal >= 10) {
		if (answerscore >= 0 && answerscore < 3) {
			currCriteria = -1;
		} else if (answerscore > 2 && answerscore < 5) {
			currCriteria = 0;
		} else if (answerscore > 4 && answerscore < 7) {
			currCriteria = 1;
		} else if (answerscore > 6 && answerscore <= 10) {
			currCriteria = 3;
		}
	} else {
		currCriteria = answerscore;
	}

	const smileyFeedback = `/static/img/feedback/smiley_${currCriteria}.png`;

	return(
		<img src={smileyFeedback} title={answerText} width={30} />
	);
};

export default RenderSmiley;