import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import {
	Grid,
	Card as MuiCard,
	CardHeader,
	CardContent as MuiCardContent,
	Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { getResponsesTotals } from "../../../redux/actions/reportsActions";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
	position: relative;

	&:last-child {
		padding-bottom: ${(props) => props.theme.spacing(4)}px;
	}
`;

const ResponsesTotals = ({ filters, theme }) => {
	const [todayTotal, setTodayTotal] = useState(0);
	const [overallTotal, setOverallTotal] = useState(0);
	const [filteredTotal, setFilteredTotal] = useState(0);

	useEffect(() => {
		let isMounted = true;

		getResponsesTotals({
			'userId': filters.userId,
			'surveyId': filters.surveyId,
			'moduleId': filters.moduleId,
			'filterOPQIds': filters.filterOPQIds,
			'dateOption': filters.dateOption,
			'startdate': filters.startdate,
			'enddate': filters.enddate
		}).then(function(result) {
			if (isMounted) {
				setTodayTotal(result.todayTotal);
				setOverallTotal(result.overallTotal);
				setFilteredTotal(result.filteredTotal);
			}
		});

		return () => { isMounted = false };
	});

	return (
		<Card mb={3}>
			<CardHeader title="Responses" />

			<CardContent>
				<Grid container spacing={6}>
					<Grid item xs={12} lg={4}>
						<Grid container spacing={6}>
							<Grid item xs={3} lg={12}>
								<strong>Today's Total</strong>
							</Grid>
							<Grid item xs={2} lg={12}>
								<strong>{todayTotal}</strong>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} lg={4}>
						<Grid container spacing={6}>
							<Grid item xs={3} lg={12}>
								<strong>Overall total</strong>
							</Grid>
							<Grid item xs={2} lg={12}>
								<strong>{overallTotal}</strong>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} lg={4}>
						<Grid container spacing={6}>
							<Grid item xs={3} lg={12}>
								<strong>Filtered total</strong>
							</Grid>
							<Grid item xs={2} lg={12}>
								<strong>{filteredTotal}</strong>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ResponsesTotals;