import React from "react";
import styled, { withTheme } from "styled-components/macro";
import {
  Grid,
  Card as MuiCard,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia as MuiCardMedia,
  IconButton,
  Typography,
  Button as MuiBtn
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { fade } from "@material-ui/core/styles/colorManipulator";

import { Line } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 350px;
  min-width: 350px;
`;
 

const Button = styled(MuiBtn)`
  float: right;
`;

const Cm = styled(CardMedia)`
    height: auto;
    width: 100%;
    min-width: 230px;
        max-width: 450px;
`;

function Approach({ theme }) {
 

  return (
    <Card mb={3}>
     
      <CardContent>

        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={6}>
            <Cm              
              component="img"
              image="https://test.dobiquity.com/images/SOAR Model.JPG"
              alt="green iguana"
            />


          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h6" gutterBottom>
              Dobiquity Approach
            </Typography>

            <Typography variant="body1" gutterBottom>
              Having a clear path to building a great business is key. The Dobiquity Continuous Improvement System is first and foremost a mindset, one focused on rising above the competition, of standing out from the crowd. This System seeks to transform how you think about continuous improvement. Each dimension will guide you towards achieving excellence.
            </Typography>
          </Grid>
        </Grid>

         

         
        
      </CardContent>
      <CardActions>
        <div style={{ width: "50%", margin: "auto" }}>
          <Button size="small" color="primary" href={`/accessKnowledgeInsights/`}>
            Read Insights
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}
export default withTheme(Approach);
