import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { Field, Form, Formik, FormikProps } from 'formik';

import EditIcon from "@material-ui/icons/Edit";

import {

	Info,
	Film,
	Send,
	Database as IconDB
} from "react-feather";




import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
	Divider as MuiDivider,
	Typography as MuiTypography,
	Card as MuiCard,
	Breadcrumbs as MuiBreadcrumbs,

} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { lighten, makeStyles } from "@material-ui/core/styles";
import { pink } from '@mui/material/colors';

const Spacer = styled.div(spacing);


import {
	Box,
	Button as MuiButton,
	Grid,
	TextField as MuiTextField
} from "@material-ui/core";

import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";

import SurveyModal from "./SurveyModal";
import SurveyQuestionAnswer from "./SurveyQuestionAnswer";

import {
	Radio as MuiRadio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	FormHelperText
} from "@material-ui/core";



const Typography = styled(MuiTypography)(spacing);


const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)(spacing);


function SurveyQuestion(questionData, handleChange) {
	/*const { onClose, selectedValue, open } = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (value) => {
		onClose(value);
	};*/

	//console.log(questionData.questionData);

	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};


	const [value, setValue] = React.useState('');
	const [error, setError] = React.useState(false);
	//const [helperText, setHelperText] = React.useState('');
	const [helperText, setHelperText] = React.useState('Please choose an answer');

	const handleRadioChange = (event) => {
		setValue(event.target.value);
		setHelperText(' ');
		setError(false);

	};

	const Radio = styled(MuiRadio)`position: relative;`;


	return (
		<Grid justify="space-between" container spacing={6}>
			<Grid item md={1}>
				<Button size="small" color="" startIcon={<Info />} onClick={handleClickOpen}></Button>
				<SurveyModal open={open} onClose={handleClose} title={questionData.questionData.criteriaText} content={questionData.questionData.criteriaGuidianceIndicator} />

			</Grid>
			{/* <Grid item xs={4}>
				<Typography variant="body2" gutterBottom>
					{questionData.questionData.criteriaText}
				</Typography>

			</Grid> */}
			<Grid item xs={11}>

				<FormControl>
					<FormLabel id="demo-row-radio-buttons-group-label">{questionData.questionData.criteriaText}</FormLabel>




					<RadioGroup
						row
						aria-labelledby="demo-row-radio-buttons-group-label"
						name={questionData.questionData.criteriaId}
						defaultValue=""
						onChange={handleRadioChange}
					>

						{questionData.questionData.answers.map((answer, index) => (


							<FormControlLabel value={answer} control={

								<Radio
									required={true}
									key={"ans_" + questionData.questionData.criteriaId + answer}
									//onChange={handleChange}
									value={answer}
									name={"ans_" + questionData.questionData.criteriaId}
									sx={{
										color: pink[800],
										'&.Mui-checked': {
											color: pink[600],
										},
									}}
								/>} label={index} />



						))}

					</RadioGroup>
					<FormHelperText>{helperText}</FormHelperText>
				</FormControl>

			</Grid>
		</Grid>
	);
}

export default withTheme(SurveyQuestion);


//{/* <SurveyQuestionAnswer questionId={questionData.questionData.criteriaId} index={index} answer={answer} /> */}