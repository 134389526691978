import React, { useState } from "react";
import styled from "styled-components/macro";

import {
	Grid,
	Card as MuiCard,
	CardContent,
	CardHeader,
	InputLabel,
	Select,
	MenuItem,
	Button
} from "@material-ui/core";

import FilterListIcon from "@material-ui/icons/FilterList";
import { Close as CloseIcon } from "@material-ui/icons";

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import { spacing } from "@material-ui/system";
import { render } from "react-dom";

const Card = styled(MuiCard)(spacing);

function formatDateToDisplay(currDate) {
	return [currDate.getDate(), currDate.getMonth() + 1, currDate.getFullYear()].join('/');
}

function formatDate(currDate) {
	return [currDate.getFullYear(), currDate.getMonth() + 1, currDate.getDate()].join('-');
}

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

function Filters({ dateOption, startdate, enddate, bindIt}) {
	const [filterDateOption, setDateOption] = useState(dateOption);
	const [filterStartdate, setFilterStartdate] = useState(startdate);
	const [filterEnddate, setFilterEnddate] = useState(enddate);
	const [filterClear, setFilterClear] = useState(false);

	const handleStartDateChange = (event) => {
		const {
			target: { value },
		} = event;
		setFilterStartdate(value);
	}

	const handleEndDateChange = (event) => {
		const {
			target: { value },
		} = event;
		setFilterEnddate(value);
	}

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setDateOption(value);
		setCurrentDatesFilters(value);
	};

	var today = new Date();
	var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
	var last7Days = new Date(new Date().setDate(new Date().getDate() - 7));
	var last30Days = new Date(new Date().setDate(new Date().getDate() - 30));
	var thisMonth = new Date().getMonth();

	var dateOptions = [
		{ 'id': 'today', 'name': 'Today - ' + formatDateToDisplay(today) },
		{ 'id': 'Yesterday', 'name': 'Yesterday - ' + formatDateToDisplay(yesterday) },
		{ 'id': 'last7Days', 'name': 'Last 7 Days - ' + formatDateToDisplay(last7Days) + ' - ' + formatDateToDisplay(today) },
		{ 'id': 'last30Days', 'name': 'Last 30 Days - ' + formatDateToDisplay(last30Days) + ' - ' + formatDateToDisplay(today) },
		{ 'id': 'thisMonth', 'name': 'This Month - ' + monthNames[thisMonth] },
		{ 'id': 'lastMonth', 'name': 'Last Month - ' + monthNames[thisMonth - 1] },
		{ 'id': 'allDates', 'name': 'All Dates' },
		{ 'id': 'customRange', 'name': 'Custom Range' }
	];

	const setCurrentDatesFilters = (arg_currDateOption = '', updateParent = false) => {
		var currDateOption = arg_currDateOption || dateOption;
		var currStartDate = '';
		var currEndDate = '';

		switch (currDateOption) {
			case 'today':
				var today = new Date();
				var currStartDate = formatDate(today);
				var currEndDate = formatDate(today);
			break;

			case 'Yesterday':
				var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
				var currStartDate = formatDate(yesterday);
				var currEndDate = formatDate(yesterday);
			break;

			case 'last7Days':
				var today = new Date();
				var last7Days = new Date(new Date().setDate(new Date().getDate() - 7));
				var currStartDate = formatDate(last7Days);
				var currEndDate = formatDate(today);
			break;

			case 'last30Days':
				var today = new Date();
				var last30Days = new Date(new Date().setDate(new Date().getDate() - 30));
				var currStartDate = formatDate(last30Days);
				var currEndDate = formatDate(today);
			break;

			case 'thisMonth':
				var today = new Date();
				var currDate = [today.getFullYear(), today.getMonth() + 1, 1].join('-');
				var currStartDate = currDate;
				var currEndDate = formatDate(today);
			break;

			case 'lastMonth':
				var date = new Date(new Date().setDate(new Date().getMonth()));
				var firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
				var lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);

				var currStartDate = [firstDayPrevMonth.getFullYear(), firstDayPrevMonth.getMonth() + 1, firstDayPrevMonth.getDate()].join('-');
				var currEndDate = [lastDayPrevMonth.getFullYear(), lastDayPrevMonth.getMonth() + 1, lastDayPrevMonth.getDate()].join('-');
			break;

			case 'allDates':
				var currStartDate = '';
				var currEndDate = '';
			break;

			case 'customRange':
				var date = new Date(new Date().setDate(new Date().getMonth()));
				var firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
				var lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);

				var currStartDate = [firstDayPrevMonth.getFullYear(), firstDayPrevMonth.getMonth() + 1, firstDayPrevMonth.getDate()].join('-');
				var currEndDate = [lastDayPrevMonth.getFullYear(), lastDayPrevMonth.getMonth() + 1, lastDayPrevMonth.getDate()].join('-');
			break;
		}

		setFilterStartdate(currStartDate);
		setFilterEnddate(currEndDate);

		if (updateParent) {
			handleClickFilter('', currDateOption, currStartDate, currEndDate);
		}
	}

	const handleClickFilter = (event, arg_filterDateOption = '', arg_filterStartdate = '', arg_filterEnddate = '') => {
		let currDateOption = arg_filterDateOption || filterDateOption;
		let currStartDate = arg_filterStartdate || filterStartdate;
		let currEndDate = arg_filterEnddate || filterEnddate;

		bindIt(currDateOption, currStartDate, currEndDate);
		setFilterClear(true);
	}

	const handleClearFilters = () => {
		setDateOption('today');
		setCurrentDatesFilters('today', true);
		setFilterClear(false);
	}

	return (
		<React.Fragment>
			<Card mb={1}>
				<CardHeader title="Filters" />

				<CardContent>
					<Grid container spacing={6}>
						<Grid item xs={12} md={3}>
							<InputLabel id="demo-simple-select-label">Date Filters</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Date Filters"
								value={filterDateOption}
								onChange={handleChange}
							>
								{dateOptions != null && dateOptions.map((el, index) => (
									<MenuItem key={"dateOptn_" + index} value={el.id}>{el.name}</MenuItem>
								))}
							</Select>
						</Grid>

						<Grid item xs={12} md={6}>
							{filterDateOption == 'customRange' &&
								<Grid container spacing={6}>
									<Grid item xs={12} md={6}>
										<InputLabel id="startdate">Start Date</InputLabel>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<DatePicker value={filterStartdate} onChange={setFilterEnddate} />
										</MuiPickersUtilsProvider>
									</Grid>

									<Grid item xs={12} md={6}>
										<InputLabel id="enddate">End Date</InputLabel>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<DatePicker value={filterEnddate} onChange={setFilterEnddate} />
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
							}
						</Grid>

						<Grid item xs={12} md={12}>
							<Grid container>
								<Grid item xs={12} md={8}></Grid>
								<Grid item xs={12} md={2}>
									{filterClear == false
										? <Button variant="contained" color="default" disabled startIcon={<CloseIcon />} style={{width: '96%'}}>Clear Filter</Button>
										: <Button variant="contained" color="default" onClick={handleClearFilters} startIcon={<CloseIcon />} style={{width: '96%'}}>Clear Filters</Button>
									}
								</Grid>
								<Grid item xs={12} md={2}>
									<Button variant="contained" color="primary" onClick={handleClickFilter} startIcon={<FilterListIcon />} style={{width: '96%'}}>Apply Filters</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</React.Fragment>
	);
}

export default Filters;
