import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
	dashboardLayoutRoutes,
	authLayoutRoutes,
	protectedRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import ViewYourResults from '../pages/dashboards/ViewYourResults';
import AssessYourBusiness from '../pages/dashboards/AssessYourBusiness';
import AssessYourBusinessSurvey from '../pages/dashboards/AssessYourBusinessSurvey';
import AccessKnowledgeInsights from '../pages/dashboards/AccessKnowledgeInsights';

import Page404 from "../pages/auth/Page404";

import Guard from "../components/AuthGuard";

const childRoutes = (Layout, routes) =>
	routes.map(({ component: Component, guard, children, path }, index) => {
		const Guard = guard || React.Fragment;

		return children ? (
			children.map((element, index) => {
				const Guard = element.guard || React.Fragment;

				return (
					<Route key={index} path={element.path} exact render={(props) => (
						<Guard>
							<Layout>
								<element.component {...props} />
							</Layout>
						</Guard>)}
					/>
				);
			})
		) : Component ? (
			<Route key={index} path={path} exact render={(props) => (
				<Guard>
					<Layout>
						<Component {...props} />
					</Layout>
				</Guard>)}
			/>
		) : null;
});

const Routes = () => (
	<Router>
		<Switch>
			{childRoutes(DashboardLayout, dashboardLayoutRoutes)}
			{childRoutes(DashboardLayout, protectedRoutes)}
			{childRoutes(AuthLayout, authLayoutRoutes)}

			<DashboardLayout>
				<Guard>
					<Route exact path="/ViewYourResults/:sid" component={ViewYourResults}/>
					<Route exact path="/ViewYourResults/:sid/:uid" component={ViewYourResults}/>

					<Route exact path="/AssessYourBusiness/:sid" component={AssessYourBusiness} />
					<Route exact path="/AssessYourBusinessSurvey/:sid" component={AssessYourBusinessSurvey}/>

					<Route exact path="/AccessKnowledgeInsights/:sid" component={AccessKnowledgeInsights} />

				</Guard>
			</DashboardLayout>

			<Route render={() => (
				<AuthLayout>
					<Page404 />
				</AuthLayout>)}
			/>
		</Switch>
	</Router>
);

export default Routes;