import * as types from "../../constants";

export default function reducer(state = {}, actions) {
	switch (actions.type) {
		case types.AUTH_SIGN_IN_SUCCESS:
			return {
				...state,
				user: {
					id: actions.id,
					email: actions.email,
					name: actions.name,
					isSuperdmin: actions.isSuperdmin,
					subAccounts: actions.subAccounts,
					mainAccountUserId: actions.mainAccountUserId,
					parent_id: actions.parent_id
				},
			};

		case types.AUTH_SIGN_OUT:
			return {
				...state,
				user: undefined,
			};

		default:
			return state;
	}
}
