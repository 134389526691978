import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { rootReducer } from "../reducers/index";
import { loadState, saveState } from "./localStorage";

const composeEnhancers = composeWithDevTools({});

const persistedState = loadState();
const store = createStore(
	rootReducer,
	persistedState,
	composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(function() {
	saveState({
		authReducer: store.getState().authReducer
	});
})

export default store;
