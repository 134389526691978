import { 
	callAPI,
	saveSurveyAnswers as save
 } from "../../services/surveyService";

 
export function getSurveyQuestions(filters) {
	return callAPI('getSurveyQuestions', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})
}


export function getSurveyQuestionsDataV2(filters) {

	console.log("getSurveyQuestionsDataV2 filters!", filters);
	return callAPI('getSurveyQuestionsDataV2', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})
}

export function getAdminSurveys(filters) {
	return callAPI('getAdminSurveys', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})
}

export function getAdminSurveyModules(filters) {
	return callAPI('getAdminSurveyModules', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})
}

 

//Save survey answers data
export function saveSurveyAnswersAction( surveyAnswers ) {
	
	console.log("actions saveSurveyAnswers");

	return save(surveyAnswers)
		.then((response) => {
			console.log(response);
			var data = {
			//	type: types.....SAVED,
				data: response			
			}
			//dispatch(data);
			return response;
			
		})
		.catch((error) => {
			console.log(error);			
			throw error;
		});
 
}

//Get user score!
export function getUserSurveyAnswerScore(filters) {
	return callAPI('getUserSurveyAnswerScore', filters)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		})

}
