import React from "react";
import { useSelector } from "react-redux";

import { Redirect } from "react-router-dom";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
	const auth = useSelector((state) => state.authReducer);

	var urlPath = '/auth/sign-in';
	var urlParams = '';

	var urlQueryString = window.location.href.split('/');
	var isAutoLogin = 0;
	var hsh = '';

	if (urlQueryString.length >= 4) {
		hsh = urlQueryString[4];
		urlParams += '/' + hsh;
	}

	if (urlQueryString[3] == 'sign-in' && hsh.length > 0) {
		isAutoLogin = 1;
	}

	urlPath += urlParams;

	if (isAutoLogin || !auth.user) {
		return <Redirect to={urlPath} />;
	}

	return children;
}

export default AuthGuard;
