import axios from "../utils/axios";

export function callAPI(endPoint, filters) {
	return new Promise(function(resolve, reject) {
		axios.get('/api/knowledge/' + endPoint, { params: filters }).then(function(response) {
			if (response.data.error == 0) {
				resolve(response.data);
			}

			reject(response.data);
		})
	})
}