import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardActionArea,
  CardActions as MuiCardActions,
  Button as MuiButton,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@material-ui/core";

const Button = styled(MuiButton)(spacing);

import { rgba } from "polished";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

import { orange, green, blue } from "@material-ui/core/colors";

import * as Icon from "@material-ui/icons";

const CardContent = styled(MuiCardContent)`
  position: relative;
  background: ${blue[600]};
  `;

const CardActions = styled(MuiCardActions)`
  position: relative;
  background: ${blue[600]};
  `;
 
const Insights = () => {
  return (
    <Card mb={3}>
      <CardActionArea>
      <CardContent>

        <Typography variant="h6" mb={4}>
          Insights
        </Typography>         

        <Typography variant="subtitle2" mb={3}>
            <Box fontWeight="fontWeightRegular">Here, you will find articles to help you improve your overall performance for each dimension. Articles are broken down under different categories to make it easy for you to find the relevant information.</Box>
        </Typography>
        
      </CardContent>

    </CardActionArea>
      <CardActions>
        <Button size="small" href={`/accessKnowledgeInsights/`}>
          Find out more
        </Button>        
      </CardActions>
    </Card>
  );
};

export default Insights;
