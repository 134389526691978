import axios from "../utils/axios";

export function autoSignIn(credentials) {
	return new Promise((resolve, reject) => {
		axios
			.post('/api/auth/autoSignIn', credentials)
			.then((response) => {
				if (response.data.error == 0) {
					resolve(response.data.data);
				}

				reject(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	})
}

export function signIn(credentials) {
	return new Promise((resolve, reject) => {
		axios
			.post('/api/auth/sign-in', credentials)
			.then((response) => {
				if (response.data.error == 0) {
					resolve(response.data.data);
				}

				reject(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function recoverPassword(credentials) {
	return new Promise((resolve, reject) => {
		axios
			.post("/api/auth/recover-password", credentials)
			.then((response) => {
				if (response.data.error == 0) {
					resolve(response.data.data);
				}

				reject(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function resetPassword(credentials) {
	return new Promise((resolve, reject) => {
		axios
			.post("/api/auth/reset-password", credentials)
			.then((response) => {
				if (response.data.error == 0) {
					resolve(response.data.data);
				}

				reject(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function signUp(credentials) {
	return new Promise((resolve, reject) => {
		axios
			.post("/api/auth/sign-up", credentials)
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				}
				reject(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}