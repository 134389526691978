import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import Table from "./Table";
import {
	Grid,
	Divider as MuiDivider,
	Typography as MuiTypography,
	Link,
	Breadcrumbs as MuiBreadcrumbs,
} from "@material-ui/core";

import {
	getKnowledgeData
} from "../../../redux/actions/knowledgeActions";
import KnowledgeArticle from "./KnowledgeArticle";


import { spacing } from "@material-ui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);




function AccessKnowledgeInsights() {
	const [rows, setRows] = React.useState([]);
	const { sid } = useParams();	
	const urlParams = sid;
	
	var moduleId = 0;
	var categoryId = 0;

	if (urlParams ){
		if (urlParams.charAt(0) == 'm') {
			moduleId = urlParams.substring(1, urlParams.lenght);
		} else {
			categoryId = urlParams;
		}
	}	
	 
	var filters = {
		'categoryId':categoryId,
		'moduleId': moduleId,
	};

	console.log(filters);
	

	useEffect(() => {
		getKnowledgeData(filters ).then(function (result) {
			setRows(result.data);
		});
	}, []);


	return (
		<React.Fragment>		
			<Grid justify="space-between" container spacing={6}>
				<Grid item>
					<Typography variant="h3" gutterBottom>
						Access Knowledge & Insights
					</Typography>

					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<Link component={NavLink} exact to="/assessYourBusiness">
							Dashboard
						</Link>
						<Link component={NavLink} exact to="/assessYourBusiness">
							Assess Your Business
						</Link>
						<Typography>Insights</Typography>
					</Breadcrumbs>

				</Grid>

				<Grid item>
					{/* <Filters /> */}
				</Grid>
			</Grid>

			

			<Divider my={6} />

			<Grid container spacing={6}>
				{rows.map(function (row, index) {
					return (
						<Grid item xs={12} md={4} lg={3}>
							<KnowledgeArticle key={index} currArticle={row} index={index} />
						</Grid>
					);
				})}
				
			</Grid>
  
		</React.Fragment>
	);
}

export default AccessKnowledgeInsights;
