import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function SurveyGuidanceModal(props) {
	/*const { onClose, selectedValue, open } = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (value) => {
		onClose(value);
	};*/

	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle>{props.title}</DialogTitle>

			<DialogContent>
				<DialogContentText>
					{/*props.content*/}
					{<div dangerouslySetInnerHTML={{ __html: props.content }} />}
				</DialogContentText>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}

export default withTheme(SurveyGuidanceModal);
