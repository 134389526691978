import React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
	Info,
	Database as IconDB
} from "react-feather";


import { spacing } from "@material-ui/system";

import {
	Box,
	Button as MuiButton
} from "@material-ui/core";


import SurveyModal from "./SurveyModal";

const Button = styled(MuiButton)(spacing);


function SurveyQuestionContainer(questionData) {
	
	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box m={2} pt={3}>
			<Button size="small" color="" startIcon={<Info />} onClick={handleClickOpen}></Button>
			<SurveyModal open={open} onClose={handleClose} title={questionData.questionData.criteriaText} content={questionData.questionData.criteriaGuidianceIndicator} />
			
			{questionData.questionData.criteriaText}
		</Box>
		   
	);
}

export default withTheme(SurveyQuestionContainer);
