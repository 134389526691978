import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";

import {
	Card as MuiCard,
	CardContent,
	CardHeader
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import "../../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";

import { getModuleBreakDown, getModuleBreakDownLatestScore } from "../../../redux/actions/reportsActions";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
	height: 340px;
	width: 100%;
`;

const ModuleBreakdown = ({ filters, theme }) => {
	const [moduleNames, setModuleNames] = useState(null);
	const [moduleScoresData, setModuleScoresData] = useState(null);

	useEffect(() => {
		let isMounted = true;

		getModuleBreakDownLatestScore({
			'userId': filters.userId,
			'surveyId': filters.surveyId,
			'moduleId': filters.moduleId,
			'filterOPQIds': filters.filterOPQIds,
			'dateOption': filters.dateOption,
			'startdate': filters.startdate,
			'enddate': filters.enddate
		}).then(function(result) {
			if (isMounted) {
				setModuleNames(result.moduleNames);
				setModuleScoresData(result.moduleScoresData);

				console.log(result)
			}
		});

		return () => { isMounted = false };
   }, []);

	const firstDatasetColor = theme.palette.secondary.main;



	const data = {		
		labels: moduleNames,
		datasets: moduleScoresData
		//labels: [ "Demo moduleNames", "module quest" ],
		/* datasets: [
			{
				label: "Current",
				backgroundColor: firstDatasetColor,
				borderColor: firstDatasetColor,
				hoverBackgroundColor: firstDatasetColor,
				hoverBorderColor: firstDatasetColor,
				//data: [54, 67],
				data: moduleScoresData[1].data,
				barPercentage: 0.5,
				categoryPercentage: 0.5,
			},
			{
				label: "Previous",
				//backgroundColor: 'rgba(0, 117, 163, 0.9)',
				borderColor: firstDatasetColor,
				hoverBackgroundColor: firstDatasetColor,
				hoverBorderColor: firstDatasetColor,
				//data: [54, 67],
				data: moduleScoresData[0].data,
				barPercentage: 0.5,
				categoryPercentage: 0.5,
			}
		], */
	};

	 

	const options = {
		maintainAspectRatio: false,
		cornerRadius: 2,
		legend: {
			display: true,
			position: 'left',
			color: "transparent"
		},
		scales: {
			yAxes: [
				{
					gridLines: {
						display: true,
					},
					ticks: {
						beginAtZero: true,
						max: 100
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						display: false
					},
					ticks: {
						fontColor: theme.palette.text.secondary,
					},
				},
			],
		}
	};

	return (
		<Card mb={1}>
			<CardHeader title="Module Breakdown" />

			<CardContent>
				<ChartWrapper>
					  <Bar data={data} options={options} />  
				</ChartWrapper>
			</CardContent>
		</Card>
	);
};

export default withTheme(ModuleBreakdown);
